<template>
    <b-modal v-model="exibe"
             hide-footer centered
             title="Cliente"
             size="lg">
        <template v-slot:default="{ cancel, ok }">
            <form id="modal-agendamento-form"
                  @submit.prevent="salvar(ok)">
                <div class="container">
                    <div class="row">

                        <div class="col-6 fundo-tabela-cliente p-2 my-2">
                            <input-pesquisa @filtrar="filtrar"
                                            :placeholder="'Pesquise por Nome ou CPF/CNPJ...'"/>
                        </div>

                        <div class="w-100 fundo-tabela-cliente p-3">
                            <b-table striped
                                     hover
                                     responsive
                                     :items="ClientesExistentes"
                                     :fields="fields"
                                     :filter="filter"
                                     :sort-icon-left="true"
                                     class="tabela-clientes-btable app-scroll-custom mb-0">

                                <template #cell(acao)="row">
                                    <span class="btn btn-sm btn-success" @click="vincular(row.item)">Vincular</span>
                                </template>

                            </b-table>
                        </div>
                    </div>

                    <div class="row py-2 mt-2">
                        <div class="col-12">
                            <botao-modal-cancel type="button" class="float-right ml-2" @click="cancel()"> Cancelar </botao-modal-cancel>
                        </div>
                    </div>
                </div>

            </form>

        </template>

        <template v-slot:modal-footer>
            <div class="mr-5">

            </div>
        </template>
    </b-modal>
</template>

<script>
    import InputSelect from '@/components/inputs/InputSelect.vue'
    import InputPesquisa from '@/components/inputs/InputPesquisa.vue'
    import BotaoModalOk from '@/components/botoes/BotaoModalOk.vue';
    import BotaoModalCancel from '@/components/botoes/BotaoModalCancel.vue';
    export default {
        name: 'ModalClienteExistente',

        components: {
            InputSelect,
            InputPesquisa,
            BotaoModalOk,
            BotaoModalCancel
        },

        props: {
            ClientesExistentes: {
                type: Array,
                default: () => []
            }
        },

        data() {
            return {
                fields: [
                    { key: 'NomeFantasia', label: 'Nome', sortable: true, sortDirection: 'asc' },
                    { key: 'CnpjCpf', label: 'CPF/CNPJ', sortable: true, sortDirection: 'asc' },
                    { key: 'acao', label: 'Ação', thStyle: 'width: 10rem', class: 'text-center' }
                ],
                clientes: [],
                exibe: false,
                filter: null
            }
        },

        methods: {
            show() {
                this.exibe = true
            },

            limpa() {
                this.clientes = []
            },

            hide() {
                this.exibe = false
            },
            filtrar(palavra) {
                this.filter = palavra
            },
            vincular(clienteSelecionado) {
                this.$emit('salvar', clienteSelecionado.Id)
            },
        }
    }
</script>

<style scoped>
    .input-select {
        background-color: var(--cinza-2);
        color: var(--cinza-5);
        width: 100%;
        border: 1px solid var(--cinza-4);
        outline: none;
        height: 28px;
    }

        .input-select:focus {
            outline: 0;
        }

    .tabela-clientes-btable {
        background-color: #fff;
        height: calc(100vh - 335px);
        overflow-y: auto;
        margin-bottom: 0;
        font-size: 12px;
    }

    .fundo-tabela-cliente {
        background-color: var(--cinza-2)
    }
</style>